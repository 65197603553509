import {createApp} from "vue";

import Vant from "vant";
import "vant/lib/index.css";
import route from "@/router";

import App from "@/App.vue";

const app = createApp(App);

app.use(route)
    .use(Vant)
    .mount("#app");

/**
 * 检查地址栏参数是否合法
 * @description 如果参数中不包含必须的 Tenant 参数则直接跳转至首页
 * @returns {boolean} true: 参数包含 ID; false: 参数中不包含 ID
 * @author Wayne Carder
 * @since 2022-02-18 13:46
 * @version 1.0.0
 */
window.validationUrlParam = () => {
    const urlParamStr = location.search;
    if (!urlParamStr) {
        location.replace("/");
        return false;
    }
    const urlParams = new URLSearchParams(urlParamStr);
    const tenant = urlParams.get("tenant");
    if (!tenant) {
        location.replace("/");
        return false;
    }
    const id = urlParams.get("id");
    return !!id;
};

/**
 * 严格地检查地址栏参数是否合法
 * @description 地址栏参数中必须同时包含 Tenant 参数和 ID 参数才能通过校验，否则将直接跳转至首页
 * @author Wayne Carder
 * @since 2022-02-20 9:49
 * @version 1.0.0
 */
window.validationUrlParamStrictly = () => {
    if (validationUrlParam()) {
        return true;
    }
    location.replace("/");
};

/**
 * 获取地址栏参数
 * @description 该方法会调用 {@link validationUrlParam} 方法检查参数是否满足需求，然后再将参数封装成对象并返回
 * @author Wayne Carder
 * @since 2022-02-18 13:46
 * @version 1.0.0
 */
window.getAllUrlParam = () => {
    const hasDeviceID = validationUrlParam();
    const urlParams = new URLSearchParams(location.search);
    const tenant = urlParams.get("tenant");
    const cellphone = urlParams.get("cellphone");
    const workorderId = urlParams.get("workorderId");
    const origin = urlParams.get("origin");
    const customerid = urlParams.get("customerid");
    const query = {};
    if (!!tenant) {
        query.tenant = tenant;
    }
    if (!!cellphone) {
        query.cellphone = cellphone;
    }
    if (!!workorderId) {
        query.workorderId = workorderId;
    }
    if (!!origin) {
        query.origin = origin;
    }
    if (!!customerid) {
        query.customerid = customerid;
    }
    if (hasDeviceID) {
        query.id = urlParams.get("id");
    }
    return query;
};

window.getUrlParamByName = name => new URLSearchParams(location.search).get(name);

window.getUrlParam = name => !!name ? window.getUrlParamByName(name) : window.getAllUrlParam();
