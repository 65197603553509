<template>
  <!--  <router-view v-slot="{ Component }">-->
  <!--    <keep-alive :include="['Workorders','PreviewRecord']">-->
  <!--        <component :is="Component" />-->
  <!--    </keep-alive>-->
  <!--  </router-view>-->
  <router-view/>
</template>

<script>
export default {
  name: "App",
  mounted() {
    let result = {
      "xcfw": "信创一站式服务平台",
      "ncbest": "南昌普联技术服务平台",
      "ncbest-jxzyy": "江西中医药大学附属医院",
      "ncbest-ncswdx": "中共南昌市委党校",
    }
    document.title = result[this.getLocationParams("tenant")] ?? "信创一站式服务平台";
  },
  methods: {
    // 在methods里定义获取参数方法
    getLocationParams(name) {
      // 构造一个含有目标参数的正则表达式对象
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      // 匹配目标参数
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      } else {
        return null;
      }
    }
  }
};
</script>

<style>
.page-container {
  height: calc(100vh - var(--van-nav-bar-height));
  padding-top: var(--van-nav-bar-height);
}
</style>
