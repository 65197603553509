import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import("@/page/Index")
    },
    {
        path: "/navigation",
        component: () => import("@/page/navigation/Navigation")
    },
    {
        path: "/navigation-without-device",
        component: () => import("@/page/navigation/NavigationWithoutDevice")
    },
    {
        path: "/query-workorder",
        component: () => import("@/page/workorder/prepare/QueryWorkorders")
    },
    {
        path: "/workorders",
        component: () => import("@/page/workorder/prepare/Workorders")
    },
    {
        path: "/device/progressing-workorder",
        component: () => import("@/page/workorder/unassigned/DeviceProgressingWorkorder")
    },
    {
        path: "/prepare-workorder",
        component: () => import("@/page/workorder/detail/PrepareWorkorder")
    },
    {
        path: "/unassigned-workorder",
        component: () => import("@/page/workorder/detail/UnassignedWorkorder")
    },
    {
        path: "/workorder",
        component: () => import("@/page/workorder/detail/Workorder")
    },
    {
        path: "/workorder/unassigned",
        component: () => import("@/page/workorder/unassigned/CreateUnassignedWorkorder")
    },
    {
        path: "/workorder/prepare",
        component: () => import("@/page/workorder/prepare/CreatePrepareWorkorder")
    },
    {
        path: "/workorder/preparepc",
        component: () => import("@/page/workorder/prepare/CreatePrepareWorkorderpc")
    },
    {
        path: "/device",
        component: () => import("@/page/device/DeviceDetails")
    },
    {
        path: "/device/perfect-information",
        component: () => import("@/page/device/PerfectInformation")
    },
    {
        path: "/device/confirm",
        component: () => import("@/page/workorder/unassigned/ConfirmDeviceCustomerInfo")
    },
    {
        path: "/device/record",
        component: () => import("@/page/workorder/unassigned/PreviewRecord")
    },
    {
        path: "/feedback",
        component: () => import("@/page/workorder/detail/Feedback")
    },
    {
        path: "/devicerepair",
        component: () => import("@/page/Ding/DeviceRepair")
    },
    {
        path: "/customer/edit-information",
        component: () => import("@/page/customer/EditInformation")
    },
    {
        path: "/customer/create-information",
        component: () => import("@/page/customer/CreateInformation")
    }
];

export default createRouter({
    routes,
    history: createWebHistory()
});
